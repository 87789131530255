import React from 'react'

import ContactOne from '../components/contact-us/ContactOne'
import Layout from '../components/layout'

const Services = props => (
  <Layout lang="pt" title="Contacte-nos" meta={{description: 'Vamos conversar? Adoraríamos falar consigo sobre como podemos ajudar a transformar a sua ideia numa realidade.'}} rootProps={{ className: 'page contact-us' }}>
    <ContactOne lang="pt" />
  </Layout>
)

export default Services
